import { createStore } from 'redux'
import { storeConstants } from './storeConstants'

const initialState = {
  sidebarShow: true,
  communitiesDetail: {},
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case storeConstants.showSidePanel:
      return { ...state, ...rest }
    case storeConstants.communityDetails:
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
